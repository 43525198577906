<template>
  <div>
    <div class="container">
      <div class="row">
        <div class="col-sm-2"></div>
        <div class="col-sm-8">
               <div class="progress">
  <div class="progress-bar bg-success " :style="{ width: `${value}%` }" role="progressbar" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100"></div>
</div>
    </div>

        </div>
        <div class="col-sm-2"></div>

      </div>
     <br>
     <br>
    
    <form @submit.prevent="handleSubmit">
      <div v-if="step === 1">
        <section class="main" >
          <div class="container ">
            <div >               
            <div class="row">
              <div class="col-sm-3"></div>
              <div class="col-sm-6">
                <div class="star">
                    <h3 class="text-center">What is your postcode?</h3>
                    <br>
                    <br>
                    <br>
                 <div class="input-group mb-3">
                 <input
                    type="text"
                    class="form-control"
                    id='postcode'
                    placeholder="Enter Your Postcode"
                    aria-label="Recipient's username"
                    aria-describedby="button-addon2"
                    v-model='registration.postcode'
                  />
                  <button
                    class="btn btn-success"
                    type="button"
                    :disabled='isDisabled'
                    @click.prevent="next()"
                  >
                    Submit
                  </button>                   
                </div>
                <p><i style="color:red;">* Enter Postcode For Next Step</i></p>                
                </div>
              </div>
              <div class="col-sm-3"></div>
            </div>
            </div>           
          </div>
        </section>
      </div>
      <form action="">
        <div v-if="step === 2">
        <section class="step2">
          <div class="container">
            <div class="row">
              <div class="col-sm-3"></div>
              <div class="col-sm-6">
                <div class="content">
              <h2 class="text-center">Who is your current Provider?</h2>
              <p class="text-center">
                If you do not have one yet, just select ‘Don’t have one’
              </p>
              <input
                class="form-check-input"
                type="radio"
                name="Provider"
                v-model="registration.provider"
                value="SKY"
                    />
                    <label class="form-check-label"> SKY </label>
                    <hr />
                    <input
                      class="form-check-input"
                      type="radio"
                      name="Provider"
                      id="flexRadioDefault2"
                      v-model="registration.provider"
                      value="BT"
                      
                    />
                    <label class="form-check-label" for="flexRadioDefault2" value="BT">
                      BT
                    </label>
                    <hr />
                    <input
                      class="form-check-input"
                      type="radio"
                      name="Provider"
                      id="flexRadioDefault2"
                    
                      v-model="registration.provider"
                      value="EE"
                    />
                    <label class="form-check-label" for="flexRadioDefault2"> EE </label>
                    <hr />
                    <input
                      class="form-check-input"
                      type="radio"
                      name="Provider"
                      id="flexRadioDefault2"
                    
                      v-model="registration.provider"
                      value="Vergin Media"
                    />
                    <label class="form-check-label" for="flexRadioDefault2">
                      Vergin Media
                    </label>
                    <hr />
                    <input
                      class="form-check-input"
                      type="radio"
                      name="Provider"
                      id="flexRadioDefault2"
                    
                      v-model="registration.provider"
                      value="other"
                    />
                    <label class="form-check-label" for="flexRadioDefault2"> Other </label>
                    <hr />
                    <input
                      class="form-check-input"
                      type="radio"
                      name="Provider"
                      id="flexRadioDefault2"
                      v-model="registration.provider"
                      value="Don't Have Account"
                    />
                    <label class="form-check-label" for="flexRadioDefault2">
                      Don't Have Account
                    </label>
                    <hr />
          <div class="next-button">
             <button @click.prevent="prev()" class="btn btn-primary">
                    Previous
                  </button>
                  <button @click.prevent="next()" class="btn btn-success next"  :disabled='isDisabled1'>Next</button>
          </div>
                 
                </div>
              </div>
              <div class="col-sm-4"></div>
            </div>
          </div>
        </section>
      </div>
      </form>
      <div v-if="step === 3">
        <section class="step3">
          <div class="container">
            <div class="row">
              <div class="col-sm-3"></div>
              <div class="col-sm-6">
                <div class="content">
              <h1 class="text-center">What Broadband Speed do you need?</h1>
              <input
                  class="form-check-input"
                  type="radio"
                  name="speed"
                  id="flexRadioDefault2"
                  value="0-30mbps"
                  v-model="registration.speed"
                />
                <label class="form-check-label" for="flexRadioDefault2">
                  0-30mbps
                </label>
                <hr />
                <input
                  class="form-check-input"
                  type="radio"
                  name="speed"
                  id="flexRadioDefault2"
                  v-model="registration.speed"
                  value="30-60mbps"
                  
                />
                <label class="form-check-label" for="flexRadioDefault2">
                  30-60 mbps
                </label>
                <hr />
                <input
                  class="form-check-input"
                  type="radio"
                  name="speed"
                  id="flexRadioDefault2"
                
                  v-model="registration.speed"
                  value="60mbps+"
                />
                <label class="form-check-label" for="flexRadioDefault2">
                  60 mbps+
                </label>
                <hr />
       
                <button @click.prevent="prev()" class="btn btn-primary">Previous</button>
                <button @click.prevent="next()" class="btn btn-success next" :disabled='isDisabled2'>Next</button>
                </div>
              </div>
              <div class="col-sm-4"></div>
            </div>
          </div>
        </section>
      </div>

      <div v-if="step === 4">
        <section class="step4">
          <div class="container">
            <div class="row">
              <div class="col-sm-2"></div>
              <div class="col-sm-8">
                <div class="content">
                  <h2 class="text-center">What type of Broadband do you need?</h2>
                 
                    <input
                      class="form-check-input"
                      type="radio"
                      name="brand"
                      id="flexRadioDefault2"
                      v-model="registration.brand"
                      @Click="isFiber=!isFiber"
                      value="Fiber"
                     
                    />
                    <label class="form-check-label" for="flexRadioDefault2"> Fiber</label>
                    <hr />
                    <input
                      class="form-check-input"
                      type="radio"
                      name="brand"
                      id="flexRadioDefault2"
                    @Click="isStandard=!isStandard"
                      v-model="registration.brand"
                      value="Standard"
                    />
                    <label class="form-check-label" for="flexRadioDefault2">
                      Standard
                    </label>
                    <hr />
                    <input
                      class="form-check-input"
                      type="radio"
                      name="brand"
                      id="flexRadioDefault2"
                    @Click="isNotSure=!isNotSure"
                      v-model="registration.brand"
                      value="Not Sure"
                    />
                    <label class="form-check-label" for="flexRadioDefault2">
                      Not Sure
                    </label>
                    <hr />

                    <button @click.prevent="prev()" class="btn btn-primary">Previous</button>
                    <button @click.prevent="next()" class="btn btn-success next" :disabled='isDisabled3'>Next</button>
                  </div>
                </div>
              <div class="col-sm-2"></div>
            </div>
          </div>
        </section>
      </div>

       <div v-if="step === 5">
        <section class="step5">
          <div class="container">
            <div class="row">
              <div class="col-sm-2"></div>
              <div class="col-sm-8">
                <div class="content">
                  <div class="row">
                    <div class="col-sm-6">
                      <div class="provider">
                    <h4>Provider</h4>
                       <img src="../assets/fiber.png" v-if="isFiber" class="img-fluid" alt="Responsive image">
                  
                  <img src="../assets/standard.png" v-if="isStandard" class="img-fluid" alt="Responsive image">
                  
                  <img src="../assets/not.png" v-if="isNotSure" class="img-fluid" alt="Responsive image">
                  </div>
                    </div>
                    <div class="col-sm-6 doller">
                       <div>
                    <h4>Monthly Cost From</h4>
                    <h1 class="price ">£17.99</h1>
                    <h6 class="">per month</h6>
                  </div>
                    </div>
                  </div>
                  <hr>
                 
                  <!-- <div class="slelect-item">
                     <p><strong>Provider:</strong>{{registration.provider}}</p>
                  <p><strong>Speed:</strong>{{registration.speed}}</p>
                  <p><strong>Broadband:</strong>{{registration.brand}}</p>
                  </div> -->
                  <br>
                  <h6>Why Choose Shell Energy?</h6>
                  <br>
                  <div class="tickmark">
                       <p>100% Unlimited Usage <span class="arrow"><img src="../assets/rightarrow.png"></span></p>
                  <hr class="ruler">
                  <p>Free installation and no setup fees <span class="arrow"><img src="../assets/rightarrow.png"></span></p>
                  <hr class="ruler">
                  <p>FREE £25 Amazon Voucher <span class="arrow"><img src="../assets/rightarrow.png"></span></p>
                  <hr class="ruler">
                  </div>
                 <br>
                  <h1 class="text-center" ><a class="btn btn-success find " href="#upper"  >Find Out More</a></h1>
                </div>
              </div>
              <div class="upper" >
                <div class="container" id="upper">
                  <div class="row">
                    <div class="col-sm-1"></div>
                    <div class="col-sm-10">
                       <div class="formdiv" >
                  
                 <h1 class="text-center form-heading">Please enter your contact details and <span class="headtext">get a free quote from Consumer Choices.</span></h1>
                 <div class="form">

           
                   <h6 v-if="errors.length">
                      <b>Please correct the following fields:</b>
                      <ul>
                        <li v-for="error in errors" :key="error">{{ error }}</li>
                      </ul>
                    </h6>
                    <input
                      class="form-control input"
                      type="text"
                      name="firstname"
                      v-model="registration.firstname"
                      placeholder="Enter Your First Name"
                    />
                    <br>
                    
                    <input
                      class="form-control input"
                      type="text"
                      name="lastname"
                      v-model="registration.lastname"
                      placeholder="Enter Your Last  Name"
                    />
                    <br>
                   
                    <input
                      class="form-control input"
                      type="text"
                      name="mobile"
                      v-model="registration.phone"
                      placeholder="Enter Your Mobile Number"
                    />
                    <br>
                    <input
                      class="form-control input"
                      type="email"
                      name="email"
                      v-model="registration.email"
                      placeholder="Enter Your Email"
                    />
                    <br>
                    
                    <!-- <button @click.prevent="prev()" class="btn btn-primary">Previous</button> -->
                    <button class="btn btn-success next find2" @click="checkForm">Submit</button>
                  </div>
                  <br>
               
                  
                </div>
                 </div>
                    <div class="col-sm-1"></div>
                  </div>
                </div>
               
              </div>
              
            </div>
          </div>
        </section>
      </div>
    </form>
  </div>
</template>
<script>
import { computed } from '@vue/runtime-core'

 import { projectFirestore } from '../firebase/config'

export default {
  name: 'Home',
  data() {
      return {
         isFiber: false,
          isStandard: false,
          isNotSure: false,
          step: 1,
          errors: [],
          postcode:'',
           value: 0,
          btnDisable: true,
          registration: {
            provider: null,
            speed: null,
            brand: null,
            firstname: '',
            phone: '',
            email:'',
            lastname:'',
          },
          
      }
  },
  methods: {
    handleSubmit() {
      let userMessage = {
        postcode:this.registration.postcode,
        email: this.registration.email,
        provider: this.registration.provider,
        brand: this.registration.brand,
        speed: this.registration.speed,
        firstname: this.registration.firstname,
        lastname: this.registration.lastname,
        phone: this.registration.phone,
      }
        if(projectFirestore.collection('userMessages').add(userMessage)){
         this.$router.push('success')
        }
    },
    checkForm: function (e) {
        if (this.registration.firstname && this.registration.lastname && this.registration.phone && this.registration.email) {
          return true;
        }

        this.errors = [];

        if (!this.registration.firstname) {
          this.errors.push('First Name required.');
        }
        if (!this.registration.lastname) {
          this.errors.push('Lastname is required.');
        }
        if (!this.registration.phone) {
          this.errors.push('mobile is required.');
        }
        if (!this.registration.email) {
          this.errors.push('email is required.');
        }

        e.preventDefault();
      },
      
      prev() {
        this.step--;
        this.value -= 20;
      },
      next() {
        this.step++;
        this.value += 20;
      },
  
  },
 computed: {
  	isDisabled: function(){
    	return !this.registration.postcode;
    },
   	isDisabled1: function(){
    	return !this.registration.provider;
    },
    	isDisabled2: function(){
    	return !this.registration.speed;
    },
    isDisabled3: function(){
    	return !this.registration.brand;
    },
    
   
  }
  
}
</script>
<style scoped>
body{
  padding: 0px;
  position: relative;
}
.provider{
  margin-bottom: 20px;
  padding: 0;
}

.price{
  color:#3461FF;
  padding: 0px;
}
.price h4,p{
  padding: 0;
  display: flex;
  justify-content: end;
}
img{
 margin-left: -30px;
}
.arrow{
  margin-left: 100px;
}
.headtext{
  color: #807DFF;
}
.find{
  padding: 15px 50px;
  font-size: 30px;
  border-radius: 50px;
 
}
.find2{
  padding: 10px 50px;
  font-size: 20px;
  border-radius: 50px;
 
}
 .formdiv{
   border:1px solid black;
   padding: 20px 200px;
   background: white;
 }
 .form-heading{
   color:black;
 }

.input{
  box-shadow: 0.00px 1.00px 4px 0px rgba(0,0,0,0.6);
  padding: 15px;
}
.ruler{
  margin: 0;
  padding: 0;
}
.step5 .tickmark p{
  margin: 0;
}
.step5 .tickmark p{
  display: flex;
  justify-content: space-between;
}
.step5 .doller{
  display: flex;
  justify-content: space-around;
}
</style>