import firebase from 'firebase/app'
import 'firebase/firestore'

const firebaseConfig = {
  apiKey: "AIzaSyBqNcHq1beUsAUdgXG8LYoUf4lBaj9-nVc",
  authDomain: "swapmybills-7f92d.firebaseapp.com",
  projectId: "swapmybills-7f92d",
  storageBucket: "swapmybills-7f92d.appspot.com",
  messagingSenderId: "523675577090",
  appId: "1:523675577090:web:8edfb94f82d9832ad3301b"
};
  // init firebase app
  firebase.initializeApp(firebaseConfig)

  // init firestore
  const projectFirestore = firebase.firestore()

  export { projectFirestore }