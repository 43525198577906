<template>
  <div id="app">
      <h1 class="text-center">Get Faster & Cheaper Broadband</h1>
          <h1 class="text-center">Deals From £17.99</h1>
          <p class="text-center">Simply enter your postcode and we’ll find you a deal that could save you hundreds a year with Fibre</p>
          <h3 class="text-center">Fast Speeds…</h3>    
          
    <router-view/>
  </div>
</template>

<style scoped>

</style>
