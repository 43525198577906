<template>
  <div>
     <div id="colorPicker">
    <img :src="color" :alt="color">
    <ul>
      <li v-for="(colorInfo, index) in colors" :key="index">
        <label>
          <input type="radio" :id="colorInfo.name" name="color" v-model="color" :value="colorInfo.image">
          {{colorInfo.name}}
        </label>
      </li>
    </ul>
  </div>
  </div>
</template>

<script>
export default {
           data: {
      color: undefined,
      colors: [
        {
          name: 'red',
          image: 'red.jpg'
        },
        {
          name: 'pink',
          image: 'pink.jpg'
        },
        {
          name: 'blue',
          image: 'blue.jpg'
        }
      ]
    }
}
</script>

<style>

</style>