<template>
  <div>
     <div class="container">
         <div class="row">
             <div class="col-sm-3"></div>
             <div class="col-sm-6">
                 <h2 class="text-center success">Thank you. You are almost done. </h2>
                 <h1 class="text-center">What Happens Now?</h1>
             </div>
             <div class="col-sm-3"></div>
             <p class="text-center">A Broadband Specialist from Consumer Choices will call you shortly to check your connection and discuss your chosen package.</p>
             <p class="text-center">They will be calling from <strong>0330 128 9646</strong></p>
         </div>
         
     </div>
  </div>
</template>

<script>
export default {

}
</script>

<style>

.success{
    background: #32ce8d;
    max-width: 672px;
    padding: 13px;
    border-radius: 10px;
    text-transform: capitalize;
    font-size: 25px;
    margin-top: 20px;
}
h1,p{
    padding: 5px 0;
}
</style>